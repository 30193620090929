import React from "react";
import { Link } from "gatsby";
import { IoTimer, IoPaperPlane, IoPhonePortrait } from "react-icons/io5";

import Image from "../../../components/image";
import { Container, Wrapper, Row, Box } from "../../../components/util";
import {
  CallToAction,
  FeaturePageHeader,
} from "../../../components/site";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

// import MobileVideo from "../../images/features/mobile/AddTimeInvoxyMobile.mp4";

const MobileTimesheets = (props) => (
  <Layout>
    <Seo
      title="Mobile Timesheet App | Karmly Feature"
      description="With Karmly Mobile your contractors can record their hours and request approval while they work, from wherever they are. For iPhone & Android devices."
      pathname={props.location.pathname}
    />
    <FeaturePageHeader
      title="Mobile Timesheet App"
      description="Give your candidates a beautiful experience with your agency. With Karmly Mobile your contractors can record their hours and request approval while they work, from wherever they are. Access Karmly Mobile at mobile.karmly.com."
      image="InvoxyMobile-mobile.png"
      imageMaxWidth={500}
    />
    <Container>
      <Wrapper>
        <Row className="-textCenter" stackGap={40}>
          <Box size={33}>
            <h3>
              <IoTimer />
              <br />
              Mobile Timesheets
            </h3>
            <p>
              Contractors can enter hours, breaks and notes on their placements
              and projects.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoPaperPlane />
              <br />
              Fast Approval Requests
            </h3>
            <p>
              Once timesheets have been entered it only takes a couple of
              seconds to send an approval request to the client manager.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoPhonePortrait />
              <br />
              Consultant Access
            </h3>
            <p>
              Give your consultants optional login access to Karmly Mobile to
              view and manage their candidate timesheets and approval requests.
            </p>
          </Box>
        </Row>
        <hr />
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyMobile_TimeEntry.png"
              alt="Mobile Time Entry"
            />
          </Box>
          <Box size={50}>
            <h3>Intuitive and Fast</h3>
            <p>
              It’s quick and easy to record time in Karmly Mobile. Contractors
              just select the relevant Placement and Work and enter their start,
              end and break times. When they request timesheet approval it gives
              them a summary to check and then emails their manager with all the
              details.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Mobile Onboarding</h3>
            <p>
              Create a great first impression by getting your contractors to
              complete{" "}
              <Link to="/recruitment/features/dashboard">placement onboarding</Link> from
              their phone the first time they use Karmly, or whenever they start
              a new placement.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyMobile_Onboarding.png"
              alt="Mobile Onboarding"
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyMobile_TimeStatus.png"
              alt="TImesheet Status"
            />
          </Box>
          <Box size={50}>
            <h3>Don’t Miss a Thing</h3>
            <p>
              The approval status of each time entry is highlighted by colour
              coding so contractors can see at a glance where everything is at.
              If they’re recording time against different projects or placements
              the time entries are clearly differentiated.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Start a clock</h3>
            <p>
              For detailed task-based time tracking use Karmly’s start/stop
              timer. If you’re interrupted in the middle of a task, simply start
              another clock and return to the original when you’re ready.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyMobile_StartClock.png"
              alt="Start & Stop Clock for Mobile"
            />
          </Box>
        </Row>
        <p className="-textCenter">
          To access Karmly Mobile simply go to{" "}
          <a
            href="https://mobile.karmly.com/#/login"
            target="_blank"
            rel="noreferrer"
          >
            mobile.karmly.com
          </a>
          in your web browser. If you're after more information please visit{" "}
          <a
            href="https://support.karmly.com/hc/en-us/articles/360002636196-Invoxy-Mobile-App-for-Resources"
            target="_blank"
            rel="noreferrer"
          >
            this support article
          </a>
          .
        </p>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default MobileTimesheets;
